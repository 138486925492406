import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Badge,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

function EstadoCuenta() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_ESTADO_CUENTA = process.env.REACT_APP_URL_ESTADO_CUENTA;
  const URL_ABONOS = process.env.REACT_APP_URL_ABONOS;
  const URL_ARTICULOS_SURTIDOS = process.env.REACT_APP_URL_ARTICULOS_SURTIDOS;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 30);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [clientes, setClientes] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [mailTo, setMailTo] = useState("");
  const [nombreCliente, setNombreCliente] = useState([]);
  const [saldo, setSaldo] = useState(0);

  const [total_kilos_edit, setTotalKilosEdit] = useState(0);
  const [total_metros_edit, setTotalMetrosEdit] = useState(0);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [modalMail2, setModalMail2] = useState(false);
  const toggleMail2 = () => setModalMail2(!modalMail2);
  const [modalMail3, setModalMail3] = useState(false);
  const toggleMail3 = () => setModalMail3(!modalMail3);
  const [modalMail4, setModalMail4] = useState(false);
  const toggleMail4 = () => setModalMail4(!modalMail4);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modalAgrupado, setModalAgrupado] = useState(false);
  const toggleAgrupado = () => setModalAgrupado(!modalAgrupado);

  const [modalRelacionado, setModalRelacionado] = useState(false);
  const toggleRelacionado = () => setModalRelacionado(!modalRelacionado);
  const [saldoFiltroRel, setSaldoFiltroRel] = useState("Pendientes");

  const [modalAbonos, setModalAbonos] = useState(false);
  const toggleAbonos = () => setModalAbonos(!modalAbonos);
  const [abonos, setAbonos] = useState([]);

  const [modalNota, setModalNota] = useState(false);
  const toggleNota = () => setModalNota(!modalNota);
  const [nota, setNota] = useState([]);

  const [activo, setActivo] = useState("");
  const [vendedor, setVendedor] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);
  const [movimientos, setMovimientos] = useState([]);
  const [idCliente, setIdCliente] = useState("");
  const [saldoInicial, setSaldoInicial] = useState(0);
  const [plazo, setPlazo] = useState(0);
  const [email, setEmail] = useState(0);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(`${URL_CLIENTES}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        // Array para pagination
        let arrayTabla = allClientes
          .sort((a, b) => (a.saldo < b.saldo ? 1 : -1))
          .map((a) => {
              return {
                _id: a._id,
                activo: a.is_active,
                codigo: a.codigo,
                nombre_comercial: a.nombre_comercial,
                razon_social: a.razon_social,
                vendedor: a.contacto[0].nombre + " " + a.contacto[0].apellido,
                idVendedor: a.contacto[0]._id,
                cargos: parseFloat(a.cargos),
                abonos: parseFloat(a.abonos),
                saldo: parseFloat(a.saldo),
                cliente: a.codigo + " / " + a.nombre_comercial,
                plazo: a.plazo,
                email: a.email
              };
           
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setClientes(allClientes);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });


    let SI = movimientos
      .map((c) => {
        if (selectedFechaInicio) {
          if (
            (selectedFechaInicio == "" || selectedFechaInicio > c.fecha)
          ) {
            return c.cargo - c.abono;
          }
        } else {
          return 0;
        }
      })
      .filter(function (el) {
        return el != null;
      });

    let TC = SI.reduce((t, total, index) => t + total, 0);

    setSaldoInicial(TC);
  }, [user, movimientos, selectedFechaInicio]);

  const ListaClientes = comments.sort((a, b) =>
    a.saldo < b.saldo ? 1 : -1
  );

  function PDFTabla() {
    let totalCargos = 0;
    let totalAbonos = 0;
    let totalSaldo = 0;
    const data = ListaClientes.map((a) => {
      if (
        (vendedor == 0 || vendedor == a.idVendedor) &&
        (activo == 0 || activo == a.is_active)
      ) {
        return [
          a.codigo,
          a.nombre_comercial,
          a.vendedor,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.abonos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.saldo),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Estado de Cuenta al ${endDate}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Cliente",
          "Vendedor",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalCargos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalAbonos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalSaldo),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`EstadosDeCuenta.pdf`);
  }

  function excel() {
    const dataExcel = ListaClientes.map((a) => {
      if (
        (vendedor == 0 || vendedor == a.idVendedor) &&
        (activo == 0 || activo == a.is_active)
      ) {
        return {
          Codigo: a.codigo,
          Cliente: a.nombre_comercial,
          Vendedor: a.vendedor,
          Cargos: a.cargos,
          Abonos: a.abonos,
          Saldo: a.saldo,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "EstadoCuenta";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "EstadoCuenta",
        sheetFilter: [
          "Codigo",
          "Cliente",
          "Vendedor",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
        sheetHeader: [
          "Codigo",
          "Cliente",
          "Vendedor",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let totalCargos = 0;
    let totalAbonos = 0;
    let totalSaldo = 0;
    const data = ListaClientes.map((a) => {
      if (
        (vendedor == 0 || vendedor == a.idVendedor) &&
        (activo == 0 || activo == a.is_active)
      ) {
        return [
          a.codigo,
          a.nombre_comercial,
          a.vendedor,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.abonos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.saldo),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Estado de Cuenta al ${endDate}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Cliente",
          "Vendedor",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
      foot: [
        [
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalCargos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalAbonos),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalSaldo),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Estado de Cuenta",
          email: mailTo,
          fileName: "EstadoCuenta.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras los saldos de los clientes.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setMailTo("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Codigo", field: "codigo", sortable: true },
    { name: "Nombre Comercial", field: "nombre_comercial", sortable: true },
    { name: "Vendedor", field: "vendedor", sortable: true },
    { name: "Cargos", field: "cargos", sortable: true },
    { name: "Abonos", field: "abonos", sortable: true },
    { name: "Saldo", field: "saldo", sortable: true },
    { name: "Estado Cuenta", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.nombre_comercial
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.vendedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.cargos.toString().includes(search) ||
          comment.abonos.toString().includes(search) ||
          comment.codigo.toString().includes(search) ||
          comment.saldo.toString().includes(search)
      );
    }
   
    if (vendedor) {
      computedComments = computedComments.filter((e) =>
        e.idVendedor.includes(vendedor)
      );
    }
   
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }
   

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "codigo" &&
      sorting.field != "cargos" &&
      sorting.field != "abonos" &&
      sorting.field != "saldo"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "cargos" ||
        sorting.field == "codigo" ||
        sorting.field == "abonos" ||
        sorting.field == "saldo")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "cargos" ||
        sorting.field == "abonos" ||
        sorting.field == "codigo" ||
        sorting.field == "saldo")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    vendedor,
    activo,
  ]);

  async function jalaMov(id, cliente, plazo, saldo, email) {
    setMovimientos([])
    await axios
      .get(`${URL_ESTADO_CUENTA}/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allVentas = response.data;
        setMovimientos(allVentas);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    setNombreCliente(cliente);
    setIdCliente(id);
    setSaldo(saldo);
    setPlazo(plazo);
    setMailTo(email);
    toggle();
  }

  async function jalaMovAgrupado(id, cliente, plazo, saldo, email) {
    setMovimientos([])
    await axios
      .get(`${URL_ESTADO_CUENTA}Agrupado/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allVentas = response.data;
        setMovimientos(allVentas);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    setNombreCliente(cliente);
    setIdCliente(id);
    setSaldo(saldo);
    setPlazo(plazo);
    setMailTo(email);
    toggleAgrupado();
  }

  async function jalaMovRel(id, cliente, plazo, saldo, email) {
    setMovimientos([])
    await axios
      .get(`${URL_ESTADO_CUENTA}Relacionado/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allVentas = response.data;
        setMovimientos(allVentas);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    setNombreCliente(cliente);
    setIdCliente(id);
    setSaldo(saldo);
    setPlazo(plazo);
    setMailTo(email);
    toggleRelacionado();
  }

  async function jalaAbonosNotaCargo(id) {
    setAbonos([])
    await axios
      .get(`${URL_ABONOS}NotaCargo/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAbonos = response.data;
        setAbonos(allAbonos);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    toggleAbonos();
  }

  async function jalaAbonosNota(id) {
    setAbonos([])
    await axios
      .get(`${URL_ABONOS}Nota/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAbonos = response.data;
        setAbonos(allAbonos);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    toggleAbonos();
  }

  async function jalaNota(id, fecha, numero, subTotal, iva, total_general) {
    await axios
      .get(`${URL_ARTICULOS_SURTIDOS}/surtido/${id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosSurtido = response.data;
        let arrayTabla = allArticulosSurtido
          .map((a) => {
            return {
              id: a._id,
              articulos: a.inventarios[0].articulos[0].nombre + " " + a.inventarios[0].articulos[0].codigo,
              unidad: a.inventarios[0].articulos[0].unidad,
              colores: a.inventarios[0].colores[0].name,
              cantidad: a.inventarios[0].cantidad,
              idArticulo: a.inventarios[0].articulos[0]._id,
              idColor: a.inventarios[0].colores[0]._id,
              precio: a.precio,
              total: parseFloat(a.inventarios[0].articulosPedido[0].precio) * parseFloat(a.inventarios[0].cantidad) ,
              tono: a.inventarios[0].tono,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        let cantidadRollos = dataFinal.length;

        let agrupado = dataFinal.reduce(function (groups, item) {
          const val = item["idArticulo"] + item["idColor"] + item["tono"];
          groups[val] = groups[val] || {
            idArticulo: item.idArticulo,
            idColor: item.idColor,
            tono: item.tono,
            cantidad: 0,
            rollos: 0,
            total: 0,
          };
          groups[val].cantidad += item.cantidad;
          groups[val].total += item.total;
          groups[val].articulos = item.articulos;
          groups[val].idArticulo = item.idArticulo;
          groups[val].idColor = item.idColor;
          groups[val].unidad = item.unidad;
          groups[val].colores = item.colores;
          groups[val].precio = item.precio;
          groups[val].tono = item.tono;
          groups[val].rollos += 1;
          return groups;
        }, []);

        let agrupadoValues = Object.values(agrupado);

        let mts = agrupadoValues.filter((e) => e.unidad == "Metros");
        let totalMts = mts.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalMetrosEdit(TM);
        let KG = agrupadoValues.filter((e) => e.unidad == "Kilos");
        let totalKG = KG.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);
        setTotalKilosEdit(TK);

        setNota(agrupadoValues)
        toggleNota();

        // const data = agrupadoValues.map((a) => {
        //   return [
        //     a.articulos,
        //     a.colores,
        //     a.tono,
        //     new Intl.NumberFormat("en-US").format(a.cantidad),
        //     new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.precio),
        //     new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.total),
        //   ];
        // });
        // const dataPDF = Object.values(data);
        // const dataPDFLimpia = dataPDF.filter(function (el) {
        //   return el != null;
        // });
        // const doc = new jsPDF();
        // var img = new Image();
        // img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        // doc.addImage(img, "png", 150, 10, 35, 10);
        // var img2 = new Image();
        // img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        // doc.addImage(img2, "png", 80, 10, 30, 12);
        
        
        // doc.setFontSize(10);
        // doc.text(`Cliente ${clientes}`, 15, 25);
        // doc.text(`Plazo ${plazo} días`, 15, 30);
        // doc.text(
        //   `Metros ${new Intl.NumberFormat("en-US").format(TM)}`,
        //   15,
        //  35
        // );

        // doc.text(`Fecha ${fecha}`, 110, 25);
        // doc.text(`Nota # ${numero}`, 110, 30);
        // doc.text(`Forma de pago: Pago en una sola exhibición`, 110, 35);
        // doc.text(`Método de pago y cuenta: PPD`, 110, 40);
       
        // doc.autoTable({
        //   head: [
        //     [
        //       "Articulo",
        //       "Color",
        //       "Tono",
        //       "Cantidad",
        //       "Precio",
        //       "Total",
        //     ],
        //   ],
        //   body: dataPDFLimpia,
        //   startY: 60,
        //   foot: [["", "", "", "", "Sub Total", new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(subTotal)],
        //   ["", "", "", "", "IVA", new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(iva)],
        //   ["", "", "", "", "Total", new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(total_general)]],
        //   showFoot: "lastPage",
        // });

        // doc.setFontSize(10);

        // window.open(doc.output("bloburl"), "_blank");
      })
      .catch((err) => {
        console.log(err);
      });
  }


  function PDFOC() {
    let saldoParcial = saldoInicial;
    let totalCargosEdoCte = 0;
    let totalAbonosEdoCte = 0;
    let totalSaldoEdoCte = 0;

    const data = movimientos.map((a) => {
      if (
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        totalCargosEdoCte = totalCargosEdoCte + a.cargo;
        totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
        totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
        saldoParcial = saldoParcial + a.cargo - a.abono;
        const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo);
        const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.abono);
        var saldoParcialFormato =
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial);
        return [
          a.fecha,
          a.movimiento,
          a.numero,
          a.empresa,
          a.factura,
          a.observaciones,
          cargos,
          abonos,
          saldoParcialFormato,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 160, 23, 25, 25);
    // doc.setFontSize(14)
    doc.text(`Estado de Cuenta al ${endDate}`, 15, 30);
    doc.text(`Cliente ${nombreCliente}`, 15, 37);
    doc.text(`Desde ${selectedFechaInicio}`, 15, 44);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Mov.",
          "No.",
          "Empresa",
          "Factura",
          "Observaciones",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 50,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "",
          { content: "Saldo Anterior", colSpan: 2, styles: { halign: "left" } },
          // "Saldo Anterior",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoInicial),
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          {
            content: "Cargos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalCargosEdoCte),
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          {
            content: "Abonos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalAbonosEdoCte),
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`EdoCtaCliente-${nombreCliente}.pdf`);
  }

  function emailFOC() {
    let saldoParcial = saldoInicial;
    let totalCargosEdoCte = 0;
    let totalAbonosEdoCte = 0;
    let totalSaldoEdoCte = 0;
    const data = movimientos.map((a) => {
      if (
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        totalCargosEdoCte = totalCargosEdoCte + a.cargo;
        totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
        totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
        saldoParcial = saldoParcial + a.cargo - a.abono;
        const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo);
        const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.abono);
        var saldoParcialFormato =
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial);
        return [
          a.fecha,
          a.movimiento,
          a.numero,
          a.observaciones,
          cargos,
          abonos,
          saldoParcialFormato,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 160, 23, 25, 25);
    // doc.setFontSize(14)
    doc.text(`Estado de Cuenta al ${endDate}`, 15, 30);
    doc.text(`Cliente ${nombreCliente}`, 15, 37);
    doc.text(`Desde ${selectedFechaInicio}`, 15, 44);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Movimiento",
          "Numero",
          "Observaciones",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 50,
      foot: [
        [
          "",
          "",
          "",
          "",
          { content: "Saldo Anterior", colSpan: 2, styles: { halign: "left" } },
          // "Saldo Anterior",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoInicial),
        ],
        [
          "",
          "",
          "",
          "",
          {
            content: "Cargos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalCargosEdoCte),
        ],
        [
          "",
          "",
          "",
          "",
          {
            content: "Abonos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalAbonosEdoCte),
        ],
        [
          "",
          "",
          "",
          "",
          { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Estado de Cuenta",
          email: mailTo,
          fileName: `EstadoCuenta-${nombreCliente}.pdf`,
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el Estado de Cuenta de ${nombreCliente}.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail2();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setMailTo("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }

  function excelFOC() {
    const dataExcel = movimientos.map((a) => {
      if (
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        return {
          Fecha: a.fecha,
          Movimiento: a.movimiento,
          Numero: a.numero,
          Observaciones: a.observaciones,
          Cargo: a.cargo,
          Abono: a.abono,
          Cargos: a.cargos,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `EdoCtaCliente-${nombreCliente}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "EstadoCuenta",
        sheetFilter: [
          "Fecha",
          "Movimiento",
          "Numero",
          "Observaciones",
          "Cargo",
          "Abono",
        ],
        sheetHeader: [
          "Fecha",
          "Movimiento",
          "Numero",
          "Observaciones",
          "Cargo",
          "Abono",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function PDFOCAgrupado() {
    let saldoParcial = saldoInicial;
    let totalCargosEdoCte = 0;
    let totalAbonosEdoCte = 0;
    let totalSaldoEdoCte = 0;

    const data = movimientos.map((a) => {
      if (
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        totalCargosEdoCte = totalCargosEdoCte + a.cargo;
        totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
        totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
        saldoParcial = saldoParcial + a.cargo - a.abono;
        const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo);
        const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.abono);
        var saldoParcialFormato =
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial);
        return [
          a.fecha,
          a.movimiento,
          a.numero,
          a.empresa,
          a.factura,
          a.osbervaciones,
          cargos,
          abonos,
          saldoParcialFormato,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 160, 23, 25, 25);
    // doc.setFontSize(14)
    doc.text(`Estado de Cuenta al ${endDate}`, 15, 30);
    doc.text(`Cliente ${nombreCliente}`, 15, 37);
    doc.text(`Desde ${selectedFechaInicio}`, 15, 44);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Mov.",
          "No.",
          "Empresa",
          "Factura",
          "Observaciones",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 50,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "",
          { content: "Saldo Anterior", colSpan: 2, styles: { halign: "left" } },
          // "Saldo Anterior",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoInicial),
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          {
            content: "Cargos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalCargosEdoCte),
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          {
            content: "Abonos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalAbonosEdoCte),
        ],
        [
          "",
          "",
          "",
          "",
          "",
          "",
          { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`EdoCtaCliente-${nombreCliente}.pdf`);
  }

  function emailFOCAgrupado() {
    let saldoParcial = saldoInicial;
    let totalCargosEdoCte = 0;
    let totalAbonosEdoCte = 0;
    let totalSaldoEdoCte = 0;
    const data = movimientos.map((a) => {
      if (
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        totalCargosEdoCte = totalCargosEdoCte + a.cargo;
        totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
        totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
        saldoParcial = saldoParcial + a.cargo - a.abono;
        const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo);
        const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.abono);
        var saldoParcialFormato =
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial);
        return [
          a.fecha,
          a.movimiento,
          a.numero,
          a.observaicones,
          cargos,
          abonos,
          saldoParcialFormato,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 10, 33, 10);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 160, 23, 25, 25);
    // doc.setFontSize(14)
    doc.text(`Estado de Cuenta al ${endDate}`, 15, 30);
    doc.text(`Cliente ${nombreCliente}`, 15, 37);
    doc.text(`Desde ${selectedFechaInicio}`, 15, 44);
    doc.autoTable({
      head: [
        [
          "Fecha",
          "Movimiento",
          "Numero",
          "Observaciones",
          "Cargos",
          "Abonos",
          "Saldo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 50,
      foot: [
        [
          "",
          "",
          "",
          "",
          { content: "Saldo Anterior", colSpan: 2, styles: { halign: "left" } },
          // "Saldo Anterior",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoInicial),
        ],
        [
          "",
          "",
          "",
          "",
          {
            content: "Cargos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalCargosEdoCte),
        ],
        [
          "",
          "",
          "",
          "",
          {
            content: "Abonos del Periodo",
            colSpan: 2,
            styles: { halign: "left" },
          },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalAbonosEdoCte),
        ],
        [
          "",
          "",
          "",
          "",
          { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Estado de Cuenta",
          email: mailTo,
          fileName: `EstadoCuenta-${nombreCliente}.pdf`,
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el Estado de Cuenta de ${nombreCliente}.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail3();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setMailTo("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }

  function excelFOCAgrupado() {
    const dataExcel = movimientos.map((a) => {
      if (
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
      ) {
        return {
          Fecha: a.fecha,
          Movimiento: a.movimiento,
          Numero: a.numero,
          Observaciones: a.observaciones,
          Cargo: a.cargo,
          Abono: a.abono,
          Cargos: a.cargos,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `EdoCtaCliente-${nombreCliente}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "EstadoCuenta",
        sheetFilter: [
          "Fecha",
          "Movimiento",
          "Numero",
          "Observaciones",
          "Cargo",
          "Abono",
        ],
        sheetHeader: [
          "Fecha",
          "Movimiento",
          "Numero",
          "Observaciones",
          "Cargo",
          "Abono",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function excelFOCRelacionado() {
    const dataExcel = movimientos.map((a) => {
      if (
        (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha) &&
        (a.cargo - a.abono > 0)
      ) {
        return {
          Fecha: a.fecha,
          Movimiento: a.movimiento,
          Numero: a.numero,
          Empresa: a.empresa,
          Factura: a.factura,
          Observaciones: a.observaciones,
          Cargo: a.cargo,
          Abono: a.abono,
          Saldo: a.cargo - a.abono,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = `EdoCtaCliente-${nombreCliente}`;

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "EstadoCuenta",
        sheetFilter: [
          "Fecha",
          "Movimiento",
          "Numero",
          "Empresa",
          "Factura",
          "Observaciones",
          "Cargo",
          "Abono",
          "Saldo",
        ],
        sheetHeader: [
          "Fecha",
          "Movimiento",
          "Numero",
          "Empresa",
          "Factura",
          "Observaciones",
          "Cargo",
          "Abono",
          "Saldo",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }
  function PDFOCRelacionado() {
    let saldoParcial = saldoInicial;
    let totalCargosEdoCte = 0;
    let totalAbonosEdoCte = 0;
    let totalSaldoEdoCte = 0;

    if(saldoFiltroRel == "Pendientes"){
      const data = movimientos.map((a) => {
        if (
          (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha) && (a.saldo > 1)
        ) {
          totalCargosEdoCte = totalCargosEdoCte + a.cargo;
          totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
          totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
          saldoParcial = saldoParcial + a.cargo - a.abono;
          const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo);
          const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.abono);
          var saldoParcialFormato =
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial);
          return [
            a.fecha,
            a.movimiento + " " + a.numero,
            a.empresa,
            a.factura,
            a.observaciones,
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.abono),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo - a.abono),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial),
          ];
        }
      });
      const dataPDF = Object.values(data);
      const dataPDFLimpia = dataPDF.filter(function (el) {
        return el != null;
      });
      const doc = new jsPDF();
      var img = new Image();
      img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
      doc.addImage(img, "png", 150, 10, 33, 10);
      var img2 = new Image();
      img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
      doc.addImage(img2, "png", 160, 20, 25, 20);
      doc.setFontSize(12)
      doc.text(`Estado de Cuenta al ${endDate}`, 15, 30);
      doc.text(`Cliente ${nombreCliente}`, 15, 35);
      doc.text(`Desde ${selectedFechaInicio}`, 15, 40);
      doc.autoTable({
        styles: {
          fontSize: 9,
        },
        head: [
          [
            "Fecha",
            "No.",
            "Empresa",
            "Factura",
            "Observ.",
            "Cargos",
            "Abonos",
            "Saldo",
            "Saldo Total",
          ],
        ],
        body: dataPDFLimpia,
        startY: 45,
        foot: [
          [
            "",
            // "",
            "",
            "",
            "",
            "",
            "",
            { content: "Saldo Anterior", colSpan: 2, styles: { halign: "left" } },
            // "Saldo Anterior",
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoInicial),
          ],
          [
            "",
            "",
            // "",
            "",
            "",
            "",
            "",
            {
              content: "Cargos del Periodo",
              colSpan: 2,
              styles: { halign: "left" },
            },
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalCargosEdoCte),
          ],
          [
            "",
            // "",
            "",
            "",
            "",
            "",
            "",
            {
              content: "Abonos del Periodo",
              colSpan: 2,
              styles: { halign: "left" },
            },
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalAbonosEdoCte),
          ],
          [
            "",
            // "",
            "",
            "",
            "",
            "",
            "",
            { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial),
          ],
        ],
        showFoot: "lastPage",
      });
      doc.save(`EdoCtaCliente-${nombreCliente}.pdf`);
    }else{
      const data = movimientos.map((a) => {
        if (
          (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
        ) {
          totalCargosEdoCte = totalCargosEdoCte + a.cargo;
          totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
          totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
          saldoParcial = saldoParcial + a.cargo - a.abono;
          const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo);
          const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.abono);
          var saldoParcialFormato =
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial);
          return [
            a.fecha,
            a.movimiento,
            a.numero,
            a.observaciones,
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.abono),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo - a.abono),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial),
          ];
        }
      });
      const dataPDF = Object.values(data);
      const dataPDFLimpia = dataPDF.filter(function (el) {
        return el != null;
      });
      const doc = new jsPDF();
      var img = new Image();
      img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
      doc.addImage(img, "png", 150, 10, 33, 10);
      var img2 = new Image();
      img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
      doc.addImage(img2, "png", 160, 23, 25, 25);
      // doc.setFontSize(14)
      doc.text(`Estado de Cuenta al ${endDate}`, 15, 30);
      doc.text(`Cliente ${nombreCliente}`, 15, 37);
      doc.text(`Desde ${selectedFechaInicio}`, 15, 44);
      doc.autoTable({
        styles: {
          fontSize: 9,
        },
        head: [
          [
            "Fecha",
            "Movimiento",
            "Numero",
            "Observaciones",
            "Cargos",
            "Abonos",
            "Saldo",
            "Saldo Total",
          ],
        ],
        body: dataPDFLimpia,
        startY: 50,
        foot: [
          [
            "",
            "",
            "",
            "",
            "",
            { content: "Saldo Anterior", colSpan: 2, styles: { halign: "left" } },
            // "Saldo Anterior",
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoInicial),
          ],
          [
            "",
            "",
            "",
            "",
            "",
            {
              content: "Cargos del Periodo",
              colSpan: 2,
              styles: { halign: "left" },
            },
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalCargosEdoCte),
          ],
          [
            "",
            "",
            "",
            "",
            "",
            {
              content: "Abonos del Periodo",
              colSpan: 2,
              styles: { halign: "left" },
            },
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalAbonosEdoCte),
          ],
          [
            "",
            "",
            "",
            "",
            "",
            { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial),
          ],
        ],
        showFoot: "lastPage",
      });
      doc.save(`EdoCtaCliente-${nombreCliente}.pdf`);
    }
    



  }

  function emailFOCRelacionado() {
    let saldoParcial = saldoInicial;
    let totalCargosEdoCte = 0;
    let totalAbonosEdoCte = 0;
    let totalSaldoEdoCte = 0;

    if(saldoFiltroRel == "Pendientes"){
      const data = movimientos.map((a) => {
        if (
          (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha) && (a.saldo > 1)
        ) {
          totalCargosEdoCte = totalCargosEdoCte + a.cargo;
          totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
          totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
          saldoParcial = saldoParcial + a.cargo - a.abono;
          const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo);
          const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.abono);
          var saldoParcialFormato =
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial);
          return [
            a.fecha,
            a.movimiento,
            a.numero,
            a.observaciones,
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.abono),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo - a.abono),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial),
          ];
        }
      });
      const dataPDF = Object.values(data);
      const dataPDFLimpia = dataPDF.filter(function (el) {
        return el != null;
      });
      const doc = new jsPDF();
      var img = new Image();
      img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
      doc.addImage(img, "png", 150, 10, 33, 10);
      var img2 = new Image();
      img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
      doc.addImage(img2, "png", 160, 23, 25, 25);
      // doc.setFontSize(14)
      doc.text(`Estado de Cuenta al ${endDate}`, 15, 30);
      doc.text(`Cliente ${nombreCliente}`, 15, 37);
      doc.text(`Desde ${selectedFechaInicio}`, 15, 44);
      doc.autoTable({
        styles: {
          fontSize: 9,
        },
        head: [
          [
            "Fecha",
            "Movimiento",
            "Numero",
            "Observaciones",
            "Cargos",
            "Abonos",
            "Saldo",
            "Saldo Total",
          ],
        ],
        body: dataPDFLimpia,
        startY: 50,
        foot: [
          [
            "",
            "",
            "",
            "",
            "",
            { content: "Saldo Anterior", colSpan: 2, styles: { halign: "left" } },
            // "Saldo Anterior",
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoInicial),
          ],
          [
            "",
            "",
            "",
            "",
            "",
            {
              content: "Cargos del Periodo",
              colSpan: 2,
              styles: { halign: "left" },
            },
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalCargosEdoCte),
          ],
          [
            "",
            "",
            "",
            "",
            "",
            {
              content: "Abonos del Periodo",
              colSpan: 2,
              styles: { halign: "left" },
            },
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalAbonosEdoCte),
          ],
          [
            "",
            "",
            "",
            "",
            "",
            { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial),
          ],
        ],
        showFoot: "lastPage",
      });
      var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Estado de Cuenta",
          email: mailTo,
          fileName: `EstadoCuenta-${nombreCliente}.pdf`,
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el Estado de Cuenta de ${nombreCliente}.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail3();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setMailTo("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
    }else{
      const data = movimientos.map((a) => {
        if (
          (selectedFechaInicio == "" || selectedFechaInicio <= a.fecha)
        ) {
          totalCargosEdoCte = totalCargosEdoCte + a.cargo;
          totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
          totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdo;
          saldoParcial = saldoParcial + a.cargo - a.abono;
          const cargos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo);
          const abonos = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.abono);
          var saldoParcialFormato =
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial);
          return [
            a.fecha,
            a.movimiento,
            a.numero,
            a.observaciones,
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.abono),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.cargo - a.abono),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial),
          ];
        }
      });
      const dataPDF = Object.values(data);
      const dataPDFLimpia = dataPDF.filter(function (el) {
        return el != null;
      });
      const doc = new jsPDF();
      var img = new Image();
      img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
      doc.addImage(img, "png", 150, 10, 33, 10);
      var img2 = new Image();
      img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
      doc.addImage(img2, "png", 160, 23, 25, 25);
      // doc.setFontSize(14)
      doc.text(`Estado de Cuenta al ${endDate}`, 15, 30);
      doc.text(`Cliente ${nombreCliente}`, 15, 37);
      doc.text(`Desde ${selectedFechaInicio}`, 15, 44);
      doc.autoTable({
        styles: {
          fontSize: 9,
        },
        head: [
          [
            "Fecha",
            "Movimiento",
            "Numero",
            "Observaciones",
            "Cargos",
            "Abonos",
            "Saldo",
            "Saldo Total",
          ],
        ],
        body: dataPDFLimpia,
        startY: 50,
        foot: [
          [
            "",
            "",
            "",
            "",
            "",
            { content: "Saldo Anterior", colSpan: 2, styles: { halign: "left" } },
            // "Saldo Anterior",
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoInicial),
          ],
          [
            "",
            "",
            "",
            "",
            "",
            {
              content: "Cargos del Periodo",
              colSpan: 2,
              styles: { halign: "left" },
            },
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalCargosEdoCte),
          ],
          [
            "",
            "",
            "",
            "",
            "",
            {
              content: "Abonos del Periodo",
              colSpan: 2,
              styles: { halign: "left" },
            },
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalAbonosEdoCte),
          ],
          [
            "",
            "",
            "",
            "",
            "",
            { content: "Saldo Actual", colSpan: 2, styles: { halign: "left" } },
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcial),
          ],
        ],
        showFoot: "lastPage",
      });
      var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Estado de Cuenta",
          email: mailTo,
          fileName: `EstadoCuenta-${nombreCliente}.pdf`,
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el Estado de Cuenta de ${nombreCliente}.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail3();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setMailTo("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
    }  
  }

  function excelAnalisis() {

    axios
    .get(`${URL_ESTADO_CUENTA}AnalisisClientes`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
     let movs = response.data

     const dataExcel = movs.map((a) => {
        return {
          IDCliente: a.idCliente,
          Cliente: a.cliente,
          Cargos: a.cargo,
          Abonos: a.abono,
          Saldo: a.saldo,
          CargosCliente: a.cargoCliente,
          AbonosCliente: a.abonoCliente,
          SaldoCliente: a.saldoCliente,
          DifCargos: a.cargo - a.cargoCliente,
          DifAbonos: a.abono - a.abonoCliente,
          DifSaldo: a.saldo - a.saldoCliente
        };
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "EstadoCuenta";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "EstadoCuenta",
        sheetFilter: [
          "IDCliente",
          "Cliente",
          "Cargos",
          "Abonos",
          "Saldo",
          "CargosCliente",
          "AbonosCliente",
          "SaldoCliente",
          "DifCargos",
          "DifAbonos",
          "DifSaldo",
        ],
        sheetHeader: [
          "IDCliente",
          "Cliente",
          "Cargos",
          "Abonos",
          "Saldo",
          "CargosCliente",
          "AbonosCliente",
          "SaldoCliente",
          "DifCargos",
          "DifAbonos",
          "DifSaldo",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();

    })
    .catch((err) => {
      console.log(err);
    });

  }


  let totalCargosTabla = 0;
  let totalAbonosTabla = 0;
  let totalSaldoTabla = 0;

  let saldoParcialTabla = saldoInicial;
  let totalCargosEdo = 0;
  let totalAbonosEdo = 0;
  let totalSaldoEdo = 0;

  let saldoParcialTablaAgrupado = saldoInicial;
  let totalCargosEdoAgrupado = 0;
  let totalAbonosEdoAgrupado = 0;
  let totalSaldoEdoAgrupado = 0;

  let saldoParcialTablaRelacionado = saldoInicial;
  let totalCargosEdoRelacionado = 0;
  let totalAbonosEdoRelacionado = 0;
  let totalSaldoEdoRelacionado = 0;

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const secondDate = new Date(hoy);
  let saldoVencido =0
  let saldoVencidoRelacionado =0
  let saldoVencidoAgrupado =0

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_admin ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              <Button
                size="sm"
                href="/MenuAdmin"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
                {/* <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excelAnalisis}
                >
                  Analisis <i class="far fa-file-excel"></i>
                </Button> */}
              </ButtonGroup>
            </Col>
          </Row>

          <h3 align="center">Estado de Cuenta</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
          
            <Col md={2}>
              <Label>Vendedor</Label>
              <Input
                bsSize="sm"
                type="select"
                value={vendedor}
                onChange={(e) => {
                  setVendedor(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    // if (a.cobrador == "Si") {
                    return (
                      <option value={a._id}>
                        {a.nombre} {a.apellido}
                      </option>
                    );
                    // }
                  })}
              </Input>
            </Col>
            <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={activo}
                required
                onChange={(e) => {
                  setActivo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((p) => {
                totalCargosTabla = totalCargosTabla + parseFloat(p.cargos);
                totalAbonosTabla = totalAbonosTabla + parseFloat(p.abonos);
                totalSaldoTabla = totalSaldoTabla + parseFloat(p.saldo);
                {
                  return (
                    <tr>
                      <td>{p.codigo}</td>
                      <td> {p.nombre_comercial}</td>
                      <td>{p.vendedor}</td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(p.cargos)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(p.abonos)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(p.saldo)}
                      </td>
                      <td>
                        <Button
                          color="info"
                          size="sm"
                          onClick={(e) =>
                            jalaMov(p._id, p.cliente, p.plazo, p.saldo, p.email)
                          }
                          id="Detalle"
                        >
                          <i class="fas fa-file-alt"></i>
                        </Button>

                        <Button
                          color="success"
                          size="sm"
                          onClick={(e) =>
                            jalaMovAgrupado(p._id, p.cliente, p.plazo, p.saldo, p.email)
                          }
                          id="Detalle"
                        >
                          <i class="far fa-newspaper"></i>
                        </Button>
                        <Button
                          color="danger"
                          size="sm"
                          onClick={(e) =>
                            jalaMovRel(p._id, p.cliente, p.plazo, p.saldo, p.email)
                          }
                          id="Detalle"
                        >
                          <i class="fas fa-file-alt"></i>
                        </Button>
                      </td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td></td>
                <td></td>
                <td className="negrita" align="right">
                  Totales
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalCargosTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalAbonosTabla)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalSaldoTabla)}
                </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="xxl" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h4>Estado de Cuenta de {nombreCliente}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={2}>
              <ButtonGroup>
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFOC}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="info"
                  onClick={toggleMail2}
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excelFOC}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>

            <Col md={2}>
              <Row>
                <Col md={6}>
                  <Label className="mr-sm-2">Plazo {plazo} dias</Label>
                </Col>
              </Row>
            </Col>

            <Col md={3}>
              <Row>
                <Col md={3}>
                  <Label className="mr-sm-2">Fecha</Label>
                </Col>
                <Col md={6}>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaInicio}
                    required
                    onChange={(e) => {
                      setSelectedFechaInicio(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col md={2}>
              <h4 align="right">
                Saldo{"  "}
                <Badge
                  id="Total"
                  color="danger"
                  className="BadgeSize badge-pill"
                >
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldo)}
                </Badge>
              </h4>
            </Col>
          </Row>
          <div className="card">
            <Table size="xxl" striped borderless className="table-responsive-xxl">
              <thead>
                <tr align="center">
                  <th className="tituloTabla" align="left">
                    Fecha
                  </th>
                  <th className="tituloTabla">Movimiento</th>
                  <th className="tituloTabla">Numero</th>
                  <th className="tituloTabla">Nota</th>
                  <th className="tituloTabla">Empresa</th>
                  <th className="tituloTabla">Factura</th>
                  <th className="tituloTabla">Observaciones</th>
                  <th className="tituloTabla">Cargos</th>
                  <th className="tituloTabla">Abonos</th>
                  <th className="tituloTabla">Saldo</th>
                </tr>
              </thead>
              <tbody>
                {selectedFechaInicio ? (
                  <tr>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="right">Saldo Anterior</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="right">
                      {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoInicial)}
                    </td>
                  </tr>
                ) : undefined}
                {movimientos
                .sort((a, b) => (a.nota > b.nota ? 1 : -1))
                .map((c) => {
                  if (
                    (selectedFechaInicio == "" ||
                      selectedFechaInicio <= c.fecha)
                  ) {
                    saldoParcialTabla = saldoParcialTabla + c.cargo - c.abono;
                    totalCargosEdo = totalCargosEdo + c.cargo;
                    totalAbonosEdo = totalAbonosEdo + c.abono;
                    totalSaldoEdo = totalCargosEdo - totalAbonosEdo;
                    let firstDate = new Date(c.fecha);
                    let diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay))-1
                    if((c.movimiento == "Nota" || c.movimiento == "Nota Cargo") && c.saldo > 1 &&  diffDays > plazo){
                      saldoVencido = saldoVencido + c.cargo
                      return (
                        <tr style={{backgroundColor:"#ed8c8c"}}>
                          <td align="center">{c.fecha}</td>
                          <td align="center">{c.movimiento}</td>
                          <td align="center">{c.numero}</td>
                          <td align="center">{c.nota}</td>
                          <td align="center">{c.empresa}</td>
                          <td align="center">{c.factura}</td>
                          <td align="center">{c.observaciones}</td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(c.cargo)}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(c.abono)}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(
                                saldoParcialTabla
                              )}
                          </td>
                        </tr>
                      )
                    }else{
                    return (
                      <tr>
                        <td align="center">{c.fecha}</td>
                        <td align="center">{c.movimiento}</td>
                        <td align="center">{c.numero}</td>
                        <td align="center">{c.nota}</td>
                        <td align="center">{c.empresa}</td>
                        <td align="center">{c.factura}</td>
                        <td align="center">{c.observaciones}</td>
                        <td align="right">
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(c.cargo)}
                        </td>
                        <td align="right">
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(c.abono)}
                        </td>
                        <td align="right">
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(
                              saldoParcialTabla
                            )}
                        </td>
                      </tr>
                    );}
                  }
                })}

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">
                    Saldo Anterior
                  </td>
                  <td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoInicial)}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">
                    Cargos del Periodo
                  </td>
                  <td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalCargosEdo)}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">
                    Abonos del Periodo
                  </td>
                  <td className="negrita" align="right">
                    {"-" + new Intl.NumberFormat("en-US",{style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalAbonosEdo)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">
                    Saldo Vencido
                  </td>
                  <td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoVencido)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">
                    Saldo Actual
                  </td>
                  <td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcialTabla)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>



      <Modal size="xxl" isOpen={modalRelacionado} toggle={toggleRelacionado}>
        <ModalHeader toggle={toggleRelacionado}>
          <h4>Estado de Cuenta de {nombreCliente}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={3}>
              <ButtonGroup>
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFOCRelacionado}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="info"
                  onClick={toggleMail4}
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excelFOCRelacionado}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>

            <Col md={2}>
                  <Label className="mr-sm-2">Plazo {plazo} dias</Label>
            </Col>

            <Col md={4}>
              <Row>
              <Col md={2}>
                  <Label className="mr-sm-2">Fecha</Label>
                  </Col>
              <Col md={10}>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaInicio}
                    required
                    onChange={(e) => {
                      setSelectedFechaInicio(e.target.value);
                    }}
                  />
                  </Col>
                  </Row>
            </Col>

            <Col md={3}>
              <h4 align="right">
                Saldo{"  "}
                <Badge
                  id="Total"
                  color="danger"
                  className="BadgeSize badge-pill"
                >
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldo)}
                </Badge>
              </h4>
            </Col>
          </Row>


            <Row>
              <Col md={3}>
                <Row>
              <Col md={2}>
                  <Label className="mr-sm-2">Saldo</Label>
                  </Col>
              <Col md={10}>
              <Input
                bsSize="sm"
                type="select"
                value={saldoFiltroRel}
                onChange={(e) => {
                  setSaldoFiltroRel(e.target.value);
                }}
              >
                <option value="Pendientes">Pendientes</option>
                <option value="Todas">Todas</option>
              </Input>
                  </Col>
                  </Row>
              </Col>
            </Row>

          

          <div className="card container">
            <Table size="sm" striped borderless className="table-responsive-xl">
              <thead>
                <tr align="center">
                  <th className="tituloTabla" align="left">
                    Fecha
                  </th>
                  <th className="tituloTabla">Movimiento</th>
                  <th className="tituloTabla">Numero</th>
                  <th className="tituloTabla">Empresa</th>
                  <th className="tituloTabla">Factura</th>
                  <th className="tituloTabla">Observaciones</th>
                  <th className="tituloTabla">Cargos</th>
                  <th className="tituloTabla">Abonos</th>
                  <th className="tituloTabla">Saldo</th>
                  <th className="tituloTabla">Saldo Total</th>
                  <th className="tituloTabla">Detalles</th>
                </tr>
              </thead>
              <tbody>
                {selectedFechaInicio ? (
                  <tr>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="right">Saldo Anterior</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="right">
                      {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoInicial)}
                    </td>
                  <td></td>
                  </tr>
                ) : undefined}
                {saldoFiltroRel == "Pendientes" ? (
                  <>
                {movimientos
                .sort((a, b) => (a.numero > b.numero ? 1 : -1))
                .map((c) => {
                  if (
                    (selectedFechaInicio == "" ||
                      selectedFechaInicio <= c.fecha) && (
                        c.saldo > 1
                      )
                  ) {
                    saldoParcialTablaRelacionado = saldoParcialTablaRelacionado + c.cargo - c.abono;
                    totalCargosEdoRelacionado = totalCargosEdoRelacionado + c.cargo;
                    totalAbonosEdoRelacionado = totalAbonosEdoRelacionado + c.abono;
                    totalSaldoEdoRelacionado = totalCargosEdoRelacionado - totalAbonosEdoRelacionado;
                    let firstDate = new Date(c.fecha);
                    let diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay))-1

                    if(c.saldo > 1 &&  diffDays > plazo){
                      saldoVencidoRelacionado = saldoVencidoRelacionado + c.cargo}
                      return (
                        // <tr style={{backgroundColor:"#ed8c8c"}}>
                          <tr
                          style={
                            c.saldo > 1 &&  diffDays > plazo
                              ? { backgroundColor: "#ed8c8c" }
                              : undefined
                              
                          }
                        >
                          <td align="center">{c.fecha}</td>
                          <td align="center">{c.movimiento}</td>
                          <td align="center">{c.numero}</td>
                          <td align="center">{c.empresa}</td>
                          <td align="center">{c.factura}</td>
                          <td align="center">{c.observaciones}</td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(c.cargo)}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(c.abono)}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(
                                c.cargo - c.abono
                              )}
                          </td>

                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(
                                saldoParcialTablaRelacionado
                              )}
                          </td>
                          <td>
                            <>
                            {c.movimiento == "Nota" ? (
                              <>
                              <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                jalaAbonosNota(c.id)
                              }
                            >
                              <i class="fas fa-dollar-sign"></i>
                            </Button>
                            <Button
                            size="sm"
                            className="btn"
                            color="info"
                            onClick={(e) =>
                              jalaNota(c.id, c.fecha, c.numero, c.subTotal, c.iva, c.cargo)
                            }
                          >
                            <i class="fas fa-file-alt"></i>
                          </Button>
                          </>
                            ):(
                              <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                jalaAbonosNotaCargo(c.id)
                              }
                            >
                              <i class="fas fa-dollar-sign"></i>
                            </Button>
                            )}
                          
                            </>
                          </td>
                        </tr>
                      )
                  }
                })}
                  </>
                ) : (
                  <>
                  {movimientos
                  .sort((a, b) => (a.fecha > b.fecha ? 1 : -1))
                  .map((c) => {
                  if (
                    (selectedFechaInicio == "" ||
                      selectedFechaInicio <= c.fecha)
                  ) {
                    saldoParcialTablaRelacionado = saldoParcialTablaRelacionado + c.cargo - c.abono;
                    totalCargosEdoRelacionado = totalCargosEdoRelacionado + c.cargo;
                    totalAbonosEdoRelacionado = totalAbonosEdoRelacionado + c.abono;
                    totalSaldoEdoRelacionado = totalCargosEdoRelacionado - totalAbonosEdoRelacionado;
                    let firstDate = new Date(c.fecha);
                    let diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay))-1

                    if(c.saldo > 1 &&  diffDays > plazo){
                      saldoVencidoRelacionado = saldoVencidoRelacionado + c.cargo}
                      return (
                        // <tr style={{backgroundColor:"#ed8c8c"}}>
                          <tr
                          style={
                            c.saldo > 1 &&  diffDays > plazo
                              ? { backgroundColor: "#ed8c8c" }
                              : undefined
                              
                          }
                        >
                          <td align="center">{c.fecha}</td>
                          <td align="center">{c.movimiento}</td>
                          <td align="center">{c.numero}</td>
                          <td align="center">{c.empresa}</td>
                          <td align="center">{c.factura}</td>
                          <td align="center">{c.observaciones}</td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(c.cargo)}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(c.abono)}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(
                                c.cargo - c.abono
                              )}
                          </td>

                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(
                                saldoParcialTablaRelacionado
                              )}
                          </td>
                          <td>
                            <>
                            {c.movimiento == "Nota" ? (
                              <>
                              <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                jalaAbonosNota(c.id)
                              }
                            >
                              <i class="fas fa-dollar-sign"></i>
                            </Button>
                            <Button
                            size="sm"
                            className="btn"
                            color="info"
                            onClick={(e) =>
                              jalaNota(c.id, c.fecha, c.numero, c.subTotal, c.iva, c.cargo)
                            }
                          >
                            <i class="fas fa-file-alt"></i>
                          </Button>
                          </>
                            ):(
                              <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) =>
                                jalaAbonosNotaCargo(c.id)
                              }
                            >
                              <i class="fas fa-dollar-sign"></i>
                            </Button>
                            )}
                          
                            </>
                          </td>
                        </tr>
                      )
                  }
                })}
                  </>
                )}


                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">
                    Saldo Anterior
                  </td>
                  <td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoInicial)}
                  </td>
                  <td></td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">
                    Cargos del Periodo
                  </td>
                  <td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalCargosEdoRelacionado)}
                  </td>
                  <td></td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">
                    Abonos del Periodo
                  </td>
                  <td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US",{style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalAbonosEdoRelacionado)}
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">
                    Saldo Vencido
                  </td>
                  <td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoVencido)}
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">
                    Saldo Actual
                  </td>
                  <td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcialTabla)}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggleAgrupado}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="xxl" isOpen={modalAgrupado} toggle={toggleAgrupado}>
        <ModalHeader toggle={toggleAgrupado}>
          <h4>Estado de Cuenta de {nombreCliente}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={2}>
              <ButtonGroup>
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFOCAgrupado}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="info"
                  onClick={toggleMail3}
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excelFOCAgrupado}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>

            <Col md={2}>
              <Row>
                <Col md={6}>
                  <Label className="mr-sm-2">Plazo {plazo} dias</Label>
                </Col>
              </Row>
            </Col>

            <Col md={3}>
              <Row>
                <Col md={3}>
                  <Label className="mr-sm-2">Fecha</Label>
                </Col>
                <Col md={6}>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaInicio}
                    required
                    onChange={(e) => {
                      setSelectedFechaInicio(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Col>

          

            <Col md={3}>
              <h4 align="right">
                Saldo{"  "}
                <Badge
                  id="Total"
                  color="danger"
                  className="BadgeSize badge-pill"
                >
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldo)}
                </Badge>
              </h4>
            </Col>
          </Row>
          <div className="card container">
            <Table size="sm" striped borderless className="table-responsive-xl">
              <thead>
                <tr align="center">
                  <th className="tituloTabla" align="left">
                    Fecha
                  </th>
                  <th className="tituloTabla">Movimiento</th>
                  <th className="tituloTabla">Numero</th>
                  <th className="tituloTabla">Empresa</th>
                  <th className="tituloTabla">Factura</th>
                  <th className="tituloTabla">Observaciones</th>
                  <th className="tituloTabla">Cargos</th>
                  <th className="tituloTabla">Abonos</th>
                  <th className="tituloTabla">Saldo</th>
                </tr>
              </thead>
              <tbody>
                {selectedFechaInicio ? (
                  <tr>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="right">Saldo Anterior</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="center">NA</td>
                    <td align="right">
                      {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoInicial)}
                    </td>
                  </tr>
                ) : undefined}
                {movimientos.map((c) => {
                  if (
                    (selectedFechaInicio == "" ||
                      selectedFechaInicio <= c.fecha)
                  ) {
                    saldoParcialTablaAgrupado = saldoParcialTablaAgrupado + c.cargo - c.abono;
                    totalCargosEdoAgrupado = totalCargosEdoAgrupado + c.cargo;
                    totalAbonosEdoAgrupado = totalAbonosEdoAgrupado + c.abono;
                    totalSaldoEdoAgrupado = totalCargosEdoAgrupado - totalAbonosEdoAgrupado;
                    let firstDate = new Date(c.fecha);
                    let diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay))-1
                    if((c.movimiento == "Nota" || c.movimiento == "Nota Cargo") && c.saldo > 1 &&  diffDays > plazo){
                      saldoVencidoAgrupado = saldoVencidoAgrupado + c.cargo
                      return (
                        <tr style={{backgroundColor:"#ed8c8c"}}>
                          <td align="center">{c.fecha}</td>
                          <td align="center">{c.movimiento}</td>
                          <td align="center">{c.numero}</td>
                          <td align="center">{c.empresa}</td>
                          <td align="center">{c.factura}</td>
                          <td align="center">{c.observaciones}</td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(c.cargo)}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(c.abono)}
                          </td>
                          <td align="right">
                            {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(
                                saldoParcialTablaAgrupado
                              )}
                          </td>
                        </tr>
                      )
                    }else{
                    return (
                      <tr>
                        <td align="center">{c.fecha}</td>
                        <td align="center">{c.movimiento}</td>
                        <td align="center">{c.numero}</td>
                          <td align="center">{c.empresa}</td>
                          <td align="center">{c.factura}</td>
                        <td align="center">{c.observaciones}</td>
                        <td align="right">
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(c.cargo)}
                        </td>
                        <td align="right">
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(c.abono)}
                        </td>
                        <td align="right">
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(
                              saldoParcialTablaAgrupado
                            )}
                        </td>
                      </tr>
                    );}
                  }
                })}

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">
                    Saldo Anterior
                  </td>
                  <td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoInicial)}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">
                    Cargos del Periodo
                  </td>
                  <td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalCargosEdoAgrupado)}
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">
                    Abonos del Periodo
                  </td>
                  <td className="negrita" align="right">
                    {"-" + new Intl.NumberFormat("en-US",{style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(totalAbonosEdoAgrupado)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">
                    Saldo Vencido
                  </td>
                  <td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoVencido)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="negrita">
                    Saldo Actual
                  </td>
                  <td className="negrita" align="right">
                    {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(saldoParcialTabla)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={toggleAgrupado}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>



      <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
        <ModalHeader toggle={toggleMail}>
          <h4>Enviar Estado de Cuenta</h4>
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={mailTo}
            required
            onChange={(e) => {
              setMailTo(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={enviaMail}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal size="sm" isOpen={modalMail2} toggle={toggleMail2}>
        <ModalHeader toggle={toggleMail2}>
          <h4>Enviar Estado de Cuenta</h4>
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={mailTo}
            required
            onChange={(e) => {
              setMailTo(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={emailFOC}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal size="sm" isOpen={modalMail3} toggle={toggleMail3}>
        <ModalHeader toggle={toggleMail3}>
          <h4>Enviar Estado de Cuenta</h4>
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={mailTo}
            required
            onChange={(e) => {
              setMailTo(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={emailFOCAgrupado}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal size="sm" isOpen={modalMail4} toggle={toggleMail4}>
        <ModalHeader toggle={toggleMail4}>
          <h4>Enviar Estado de Cuenta</h4>
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={mailTo}
            required
            onChange={(e) => {
              setMailTo(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={emailFOCRelacionado}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="xl" isOpen={modalAbonos} toggle={toggleAbonos}>
        <ModalHeader toggle={toggleAbonos}>
          <h4>Abonos</h4>
        </ModalHeader>
        <ModalBody>
        <Table size="sm" striped borderless className="table-responsive-xl">
              <thead>
                <tr>
                  <th className="tituloTabla">Fecha</th>
                  <th className="tituloTabla">Forma de Pago</th>
                  <th className="tituloTabla">Concepto</th>
                  <th className="tituloTabla">Numero</th>
                  <th className="tituloTabla">Importe</th>
                </tr>
              </thead>
              <tbody>
                {abonos.map((a)=>{
                  return(
                  <tr>
                    <td>{a.fecha}</td>
                    <td>{a.formaPago}</td>
                    <td>{a.concepto}</td>
                    <td>{a.idAbono}</td>
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.importe)}</td>
                  </tr>
                  )
                })}
              </tbody>
        </Table>
        </ModalBody>
      </Modal>

      <Modal size="xl" isOpen={modalNota} toggle={toggleNota}>
        <ModalHeader toggle={toggleNota}>
          <h4>Nota</h4>
        </ModalHeader>
        <ModalBody>
        <Table size="sm" striped borderless className="table-responsive-xl">
              <thead>
                <tr>
                  <th className="tituloTabla">Articulo</th>
                  <th className="tituloTabla">Color</th>
                  <th className="tituloTabla">Tono</th>
                  <th className="tituloTabla">Rollos</th>
                  <th className="tituloTabla">Cantidad</th>
                  <th className="tituloTabla">Unidad</th>
                  <th className="tituloTabla">Precio</th>
                  <th className="tituloTabla">Total</th>
                </tr>
              </thead>
              <tbody>
                {nota.map((a)=>{
                  return(
                  <tr>
                    <td>{a.articulos}</td>
                    <td>{a.colores}</td>
                    <td>{a.tono}</td>
                    <td>{a.rollos}</td>
                    <td>{new Intl.NumberFormat("en-US", {maximumFractionDigits: 2, }).format(a.cantidad)}</td>
                    <td>{a.unidad}</td>
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.precio)}</td>
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2, }).format(a.total)}</td>
                  </tr>
                  )
                })}
              </tbody>
        </Table>
        </ModalBody>
      </Modal>

      {loader}
    </>
  );
}

export default EstadoCuenta;
